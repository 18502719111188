import React from "react";
import { Col, Row } from "react-bootstrap";

export class TitleBlock3 extends React.Component{
    render(){
        return(
            <Row className="title_block3">
                <Col>
                    <h3>Широкий парк передового оборудования</h3>
                </Col>
            </Row>
            
        )
    }
}