import React from "react";
import { Col, Row } from "react-bootstrap";

export class Zero extends React.Component{
    render(){
        return(
            <Row className="zero_block">
                <Col>&nbsp;</Col>
            </Row>
        )
    }
}