import React from "react";
import { Col, Row } from "react-bootstrap";
import img from "../img/operator.png";

export class Block8 extends React.Component{
    render(){
        return(
            <Row className="block block5 block8">
                <Col xs={12}>
                    {/*<img src={img} alt="Оператор" />*/}
                    <p>
                        Мы обучаем выполнять различные задачи с применением <b>БПЛА</b>. 
                        Выпускники нашего учебного центра успешно выполняют задачи с 
                        воздуха в государственных и комерческих организациях.
                    </p>
                    <p>
                        Наши курсы извесны своим индивидуальным подходом к каждому, 
                        поэтому подходят для служащих в <b>ВС РФ</b>,
                        спасателям, ЧОП, геодезистам, нефтяникам, энергетикам, операторам 
                        фото и видео-съемки, туристам, блогерам, спортсменам, работникам любых сфер,
                        где необходима съемка с воздуха.
                    </p>
                </Col>
                <Col xs={12}>
                    <img src={img} alt="Оператор" />
                </Col>
            </Row>
        )
    }
}