import React from "react";
import { Col, Row } from "react-bootstrap";
import img1 from "../img/img1_block5.png";

export class Block5 extends React.Component{
    render(){
        return(
            <Row className="block block5">
                <Col>
                    <h3 className="title_align_center whitebg line_t">
                        ПРЕПОДАВАТЕЛИ С БОЕВЫМ ОПЫТОМ
                    </h3>
                    <Row className="block_line">
                        <Col xs={5}>
                            <img className="img_block" src={img1} alt="Картинка"/>
                        </Col>
                        <Col xs={7}>
                            <div className="rev_block">
                                <h4>Курсы проводятся в Новосибирске</h4>
                                <h3 className="green">НА ВОЕННОМ ПОЛИГОНЕ</h3>
                                <h4>Новосибирской области</h4>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}