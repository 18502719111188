import React from "react";
import { Col, Row } from "react-bootstrap";
import telegram from "../img/tele.svg";

export class TelButton extends React.Component{
    render(){
        return(
            <Row className="tel_block">
                <Col>
                    <a className="tele_button" href="https://skilldronesiberia.ru/svo.php">
                    <img src={telegram} alt="логотип телеграма"/>&nbsp;&nbsp;&nbsp;
                        КУПИТЬ КУРС
                    </a>
                </Col>
            </Row>
        )
    }
}