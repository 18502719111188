import React from "react";
import { Col, Row } from "react-bootstrap";
import phone from "../img/phone.svg";
import vk from "../img/vk.svg";
import site from "../img/site.svg";

export class Block10 extends React.Component{
    render(){
        return(
            <Row className="block10">
                <Col xs={12}>
                    <h3>КОНТАКТЫ</h3>
                    <img src={phone} className="phone_img" alt="трубка телефона"/>
                    <a href="tel:+73832130011" className="button_tel">
                        
                        НАШИ КОНТАКТНЫЕ<br/> ТЕЛЕФОН<br/> <span style={{color:'#727271'}}>+7 383 213 00 11</span>
                    </a>
                    <h4>НАШИ СОЦИАЛЬНЫЕ СЕТИ</h4>
                </Col>
                <Col xs={6} style={{textAlign: 'end'}}>
                    <a href="https://vk.com/skilldronesiberia">
                        <img src={vk} width={'70px'} alt="логотип вконтакте"/>
                    </a>
                </Col>
                <Col xs={6} style={{textAlign: 'left'}}>
                    <a href="https://skilldronesiberia.ru/svo.php">
                        <img src={site} width={'70px'} alt="логотип сайт"/>
                    </a>
                </Col>
                <Col xs={12}>
                    <p style={{fontSize:'7pt'}}>
                        ООО «СкиллДронСибирь»<br/> 
                        адрес: Россия, г. Новосибирск<br/>
                        ИНН: 5404952558 
                    </p>
                    <p className="copy" style={{fontSize:'7pt'}}>
                        сделано &copy; <a className="copy" href="https://siadesigngroup.ru">SIADESIGNGROUP.ru</a>
                    </p>
                </Col>
            </Row>
        )
    }
}