import React from "react";
import { Col, Row } from "react-bootstrap";
import img1 from "../img/tricolor_img2.png";
import img2 from "../img/tricolor_img3.png";

export class Block9 extends React.Component{
    render(){
        return(
            <Row className="tricolor">
                <Col xs={12}>
                    <p>
                        По завершении обучения, выдается сертификат 
                        о прохождение курсов и удостоверение о 
                        повышение квалификации установленного 
                        образца Федерального государственного 
                        бюджетного образовательного 
                        учреждения высшего образования 
                        «<b>Московского авиационного института</b>» 
                        (национальный исследовательский 
                        университет).
                    </p>
                </Col>
                <Col xs={6}>
                    <p>
                        <img src={img1} width={'100%'} alt="Образец удостоверения от ВУЗов для людей с высшим или средне-специальным образованием"/>
                    </p>
                    <p className="img_text" >Образец удостоверения от ВУЗов для людей с высшим или средне-специальным образованием</p>
                </Col>
                <Col xs={6}>
                <p>
                        <img src={img2} width={'100%'} alt="Образец удостоверения от ВУЗов для людей с высшим или средне-специальным образованием"/>
                    </p>
                    <p className="img_text">Образец удостоверения от ВУЗов для людей с высшим или средне-специальным образованием</p>
                </Col>
            </Row>
        )
    }
}