import React from "react";
import { Col, Row } from "react-bootstrap";

export class Block4 extends React.Component{
    render(){
        return(
            <Row className="block"> 
                <Col>
                    <h3 className="title_align_center">
                        ОПЕРАТОР ДРОНА В ЗОНЕ СВО
                    </h3>
                    <h3 className="title_align_center blue">
                        Курс 5 дней
                    </h3>
                    <p>
                        1.Теоритический и практический курс по пилотированию и обслуживанию FPV-дронов под воздействием давления и ограничений внешними факторами.<br/>
                        2.Длительность состовляет 5 рабочих дней подряд с 10:00 до 18:00.<br/>
                        3.Выдача диплома установленного образца от ВУЗа "Оператор БПЛА" на 40 часов, а так же сертификат Skilldrone SIBERIA об успешном завершении курса "Оператор БПЛА".
                    </p>
                    <p>
                        <small>
                            *Подробная программа курса только по индивидуальному запросу
                        </small>
                    </p>
                </Col>
            </Row>
        )
    }
}