import React from "react";
import { Col, Row } from "react-bootstrap";

export class Block7 extends React.Component{
    render(){
        return(
            <Row className="block7">
                <Col>
                    <hr noshade size="5"/>
                    <h3>
                        Какие документы
                        нужны для зачисления на обучение?
                    </h3>
                    <p>
                        Для получения удостовирения установленного образца 
                        слушатель курса должен иметь высшее или среднее 
                        профессиональное образование, либоиметь аттестат 
                        о среднем полном общем образовании.
                    </p>
                    <p>
                        Пройти обучение можно и без требуемого начального образования, но без выдачи ДПО по завершении курса.
                    </p>
                    <p>
                        <span style={{color: '#2B4390'}}>Необходимо предоставить:</span>
                        <ol>
                            <li>
                                Скан-копия паспорта: главный разворот + прописка;
                            </li>
                            <li>
                                Скан-копия диплома о высшем или средне специальном (СПО) образовании (для основания выдачи ДПО от ВУЗа); 
                            </li>
                            <li>
                                Скан-копия СНИЛСа;
                            </li>
                            <li>
                                Реквизиты компании (если проходите обучение от юридического лица, т.е. компании).
                            </li>
                        </ol>
                    </p>
                </Col>
            </Row>
        )
    }
}