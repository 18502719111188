import React from 'react';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Header } from './Components/header';
import { Block1 } from './Components/block1';
import { Block2 } from './Components/block2';
import { TelButton } from './Components/telButton';
import { Zero } from './Components/zero';
import { Block3 } from './Components/block3';
import { TitleBlock3 } from './Components/title_block3';
import { Block4 } from './Components/block4';
import { Block5 } from './Components/block5';
import { Block6 } from './Components/block6';
import { Block7 } from './Components/block7';
import { Block8 } from './Components/block8';
import { TitleBlock9 } from './Components/titleBlock9';
import { Block9 } from './Components/block9';
import { Block10 } from './Components/block10';

function App() {
  return (
    <Container fluid>
      <Row>
        <Col className='line'>
          &nbsp;
        </Col>
      </Row>
      <Header/>
      <Block1/>
      <Block2/>
      <Zero/>
      <TelButton/>
      <TitleBlock3/>
      <Block3/>
      <Block4/>
      <Block5/>
      <Block6/>
      <Block7/>
      <Block8/>
      <TitleBlock9/>
      <Block9/>
      <Block10/>
    </Container>
  );
}

export default App;
