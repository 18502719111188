import React from "react";
import { Col, Row } from "react-bootstrap";

export class TitleBlock9 extends React.Component{
    render(){
        return(
            <Row className="title_align_center whitebg line_t">
                <Col>
                    <h4>Что дают удостоверение и сертификат?</h4>
                </Col>
            </Row>
        )
    }
}