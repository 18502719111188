import React from "react";
import { Col, Row } from "react-bootstrap";
import { TelButton } from "./telButton";

export class Block6 extends React.Component{
    render(){
        return(
            <Row>
                <Col className="block6">
                    <h3>
                        Даты ближайших курсов<br/>
                        в Новосибирске<br/>
                        29.07 - 02.08<br/>
                        Оператор FPV-дронов<br/>
                        Очно<br/>
                        5 дней<br/>
                        70.000 руб.
                    </h3>
                    <TelButton/>
                </Col>
            </Row>
        )
    }
}