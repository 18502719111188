import React from "react";
import { Row } from "react-bootstrap";

export class Block1 extends React.Component{
    render(){
        return(
            <Row className="block1">
                &nbsp;
            </Row>
        )
    }
}