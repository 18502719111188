import React from "react";
import { Col, Row } from "react-bootstrap";

export class Block2 extends React.Component{
    render(){
        return(
            <Row className="zero">
                <Col className="block2">
                    <h2>ОПЕРАТОР БПЛА В ЗОНЕ СВО</h2>
                    <p>
                        Индивидуальное и груповое обучение в Новосибирске от 1 до 10 дней
                    </p>
                    <p>
                        Выдача удостоверений о повышении квалификации "ОПЕРАТОР БПЛА" 40 и 70 часов
                    </p>
                    <p>
                        <small>
                            Лицензия: Л035-01298-77/01189445 от 06.05.2024 
                        </small>
                    </p>
                </Col>
            </Row>
        )
    }
}