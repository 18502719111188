import React from "react";
import { Col, Row } from "react-bootstrap";
import img1 from "../img/img1_block3.png";
import img2 from "../img/img2_block3.png";
import img3 from "../img/img3_block3.png";
import img4 from "../img/img4_block3.png";
import img5 from "../img/img5_block3.png";

export class Block3 extends React.Component{
    render(){
        return(
            <Row className="block3">
                <Col >
                    <img src={img1} width={'100px'} alt=""/>
                </Col>
                <Col >
                    <img src={img2} width={'100px'} alt=""/>
                </Col>
                <Col >
                    <img src={img3} width={'100px'} alt=""/>
                </Col>
                <Col >
                    <img src={img4} width={'100px'} alt=""/>
                </Col>
                <Col >
                    <img src={img5} width={'100px'} alt=""/>
                </Col>
            </Row>            
        )
    }
}