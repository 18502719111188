import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from '../logo.svg';

export class Header extends React.Component{
    render(){
        return(
            <Row className="header">
                <Col xs={6}>
                    <img src={logo} style={{ verticalAlign: 'baseline',  width: '100%', marginTop: '0.5em' }} alt="Логотип СкиллДронСибериа"/>
                </Col>
                <Col xs={6}>
                    <h1 className="title">ОБУЧЕНИЕ ОПЕРАТОРОВ БПЛА(ДРОНОВ)</h1>
                </Col>
            </Row>
        )
    }
}